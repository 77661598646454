<template>
	<div>
		<CRow>
			<CCol sm="12">
				<CCard>
					<CCardBody>
						<CRow>
							<CCol sm="8">
								<h4 id="traffic" class="card-title mb-0">List Visit Card Usage</h4>
								<div class="small text-muted">{{date(new Date(), 'MMMM YYYY')}}</div>
							</CCol>
							<CCol sm="4" class="col align-self-center row justify-content-end">
								 	<CButton
										class="my-2 text-center"
										color="success"
										variant="outline"
										square
										size="sm"
										@click="generatePdf"
										>
										Generate PDF
									</CButton> &nbsp;
									<download-excel
										class="btn btn-default"
										:data="cards"
										:fields="jsonFields"
										worksheet="Sheet1"
										name="list-card-visit-usage.xls"
									>
										<CButton
										color="success"
										variant="outline"
										square
										size="sm"
										>
										Download Excel
										</CButton>
									</download-excel>
							</CCol>
						</CRow>
						<CRow class="mt-5">
							<CCol sm="3">
								<CInput
									type="text"
									label="Search.."
									placeholder=""
									description="Masukkan kata kunci pencarian."
									v-model="search"
									required
								/>
							</CCol>
							<CCol sm="3">
								<CSelect 
									:value.sync="keyword"
									name="keyword"
									label="Filter By"
									:options="keywords"
								/>
							</CCol>
							<CCol sm="3">
								<CButton
									style="margin-top: 30px;"
									color="primary"
									variant="outline"
									square
									size="sm"
									@click="filterRange"
								>
									Apply
								</CButton> &nbsp;
								<CButton
									style="margin-top: 30px;"
									color="success"
									variant="outline"
									square
									size="sm"
									@click="filterRefresh"
								>
									Refresh List
								</CButton>
							</CCol>
						</CRow>
						
						<CDataTable
							hover
							striped
							border
							small
							sorter
							:items="filteredItems"
							:fields="fields"
							:items-per-page="perPage"
							pagination
							:loading="isLoading"
						>
							<template #id="{item}">
								<td align="center">{{cards.map(function(x) {return x.id; }).indexOf(item.id)+1}}</td>
							</template>
							<template #allowing_access="{item}">
								<td>
									<p>{{item.allowing_access.split(',').length + ' Room(s)'}}</p>
								</td>
							</template>
							<template #name="{item}">
								<td>{{item.name}}<br>Role: <strong>{{item.role}}</strong></td>
							</template>
							<template #created_at="{item}">
								<td align="center">
									<p>{{item.created_at | formatDate}} {{item.created_at | formatTime}}</p>
								</td>
							</template>
							<template #action="{item}">
								<td align="center">
									<CButton color="primary" variant="outline" square size="sm" @click="editCard(item.id)">Edit</CButton> &nbsp; 
									<CButton color="danger" variant="outline" square size="sm" @click="removeCard(item.id)">Delete</CButton>
								</td>
							</template>
						</CDataTable>

						<vue-html2pdf
							:show-layout="true"
							:float-layout="false"
							:enable-download="true"
							:preview-modal="false"
							:paginate-elements-by-height="1400"
							filename="Lintasarta-Visit-Card-Usage"
							:pdf-quality="2"
							:manual-pagination="true"
							pdf-format="a4"
							pdf-orientation="portrait"
							pdf-content-width="100%"
							@hasStartedGeneration="hasStartedGeneration()"
							@hasGenerated="hasGenerated($event)"
							:html-to-pdf-options="htmlToPdfOptions"
							ref="html2Pdf"
							class="d-none"
						>
							<section slot="pdf-content" id="content">
								<CRow class="my-3">
									<CCol md="4" class="m-auto" >
										<CCol md="12" >
											No. Dok :
										</CCol>
										<CCol md="12">
											Versi : 
										</CCol>
										<CCol md="12">
											Label : 
										</CCol>
									</CCol>
									<CCol md="4" class="d-flex justify-center">
										<h4 class="m-auto">List Visit Card Usage</h4>
									</CCol>
									<CCol md="4" class="d-flex justify-center">
										<img src="@/assets/logo.png" style="height: 70px;" class="my-auto ml-auto mr-5" />
									</CCol>
								</CRow>
						
								<CDataTable
									hover
									striped
									border
									small
									sorter
									:items="filteredItems"
									:fields="fields"
									:loading="isLoading"
									class="mx-3"
								>
									<template #id="{item}">
										<td align="center">{{cards.map(function(x) {return x.id; }).indexOf(item.id)+1}}</td>
									</template>
									<template #allowing_access="{item}">
										<td>
											<p>{{item.allowing_access.split(',').length + ' Room(s)'}}</p>
										</td>
									</template>
									<template #name="{item}">
										<td>{{item.name}}<br>Role: <strong>{{item.role}}</strong></td>
									</template>
									<template #created_at="{item}">
										<td align="center">
											<p>{{item.created_at | formatDate}} {{item.created_at | formatTime}}</p>
										</td>
									</template>
									<template #action="{item}">
									<td align="center">
										<CButton color="primary" variant="outline" square size="sm" @click="editCard(item.id)">Edit</CButton> &nbsp; 
										<CButton color="danger" variant="outline" square size="sm" @click="removeCard(item.id)">Delete</CButton>
									</td>
									</template>
								</CDataTable>
							</section>
						</vue-html2pdf>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>	
</template>

<script>
import http from '@/utils/http-common';
import TheTableHeader from '@/containers/TheTableHeader.vue'
import moment from 'moment';
import VueHtml2pdf from 'vue-html2pdf';

export default {
	name: 'listAllCardVisitUsage',
	components: { TheTableHeader, VueHtml2pdf },
	data () {
		return {
			search: '',
			cards: [],
			filteredItems: [],
			fields: [
				'no', 
				'card_number', 
				'card_holder', 
				'role', 
				'company', 
				'data_center', 
				'allowed_rooms', 
				// 'accessed_on', 
			],
			jsonFields : {
				'No':'no', 
				'Card Number':'card_number', 
				'Card Holder':'card_holder', 
				'Role':'role', 
				'Company':'company', 
				'Data Center':'data_center', 
				'Allowed Rooms':'allowed_rooms', 
			},
			currentPage: 1,
			perPage: 15,
			totalRows: 1,
			isLoading: true,
			keyword: 'Company Name',
			keywords: [
				'Company Name', 'Data Center', 'Role','Card Holder'
			],
			htmlToPdfOptions: {
				margin: 0,
				filename: 'Lintasarta-Visit-Card-Usage',
				'pdf-quality': '2',
				jsPDF: {
					unit: 'mm',
					format: 'a4',
					orientation: 'portrait'
				},
				html2canvas: {
					dpi: 300,
					letterRendering: true,
					useCORS: true
				}
			},
		}
	},
	paginationProps: {
		align: 'right',
		doubleArrows: false,
		previousButtonHtml: 'prev',
		nextButtonHtml: 'next'
	},
	methods: {
		generatePdf () {
			this.$refs.html2Pdf.generatePdf();
		},
		getCards(){
			var self = this
			return http.get('/cardusage')
			.then(function (response) {
				// console.log(response.data)
				if(response.data.type != 'empty'){
					self.cards = response.data.data;
					self.filteredItems = response.data.data;
					self.isLoading = false;
				}
				
				// console.log(self.data)
			}).catch(function (error) {
				console.log(error);
			});
		},
		date(value, format) {
			return moment(String(value)).locale('Id').format(format);
		},
		filterRange() {
			let self = this;
			self.filteredItems = [];

			let data = self.cards.filter(item => {
				if (self.keyword === 'Role') {
					return item.role.toLowerCase().includes(self.search.toLowerCase());
				} else if (self.keyword === 'Data Center') {
					return item.data_center.toLowerCase().includes(self.search.toLowerCase());
				}else if (self.keyword === 'Card Holder') {
					return item.card_holder.toLowerCase().includes(self.search.toLowerCase());
				} else {
					return item.company.toLowerCase().includes(self.search.toLowerCase());
				}
			})

			self.filteredItems = data;
		},
		filterRefresh() {
			this.filteredItems = this.cards;
			this.search = '';
		},
	},
	mounted: function(){
		this.getCards();
	}
}

</script>
